import {
  Configuration,
  DefaultApiFactory,
  NextStepRes,
} from "../generated-sources/state-machine"
import { useState } from "react"
import { AxiosResponse } from "axios"
import { useRecoilState } from "recoil"
import { idCaseState, payloadState, templateState } from "../atoms/application"

/**
 * @param nextStep function which call next step on server
 * @param payload from backend can be string or json in string
 * @param template name of template which should be rendered
 */
export interface INextStep {
  nextStep: (inRequestBody: any) => void
  payload: string
  template: string | null
}

// create REST api
const api = DefaultApiFactory(
  new Configuration({
    basePath:
      window.location.protocol +
      "//" +
      window.location.hostname +
      ":" +
      window.location.port,
  })
)

const useNextStep = (): INextStep => {
  const [, setPayloadRecoil] = useRecoilState(payloadState)
  const [, setTemplateRecoil] = useRecoilState(templateState)
  const [template, setTemplate] = useState<string>("")
  const [payload, setPayload] = useState<string>("")

  const nextStep = async (inRequestBody = {}) => {
    const requestBody = {
      ...inRequestBody,
    }
    const response: AxiosResponse<NextStepRes> = await api.apiNextStatusPost(
      requestBody
    )
    const template = response.data.template || ""
    setTemplate(template)
    setTemplateRecoil(template)
    const payload: string =
      typeof response.data.payload === "string"
        ? response.data.payload
        : JSON.stringify(response.data.payload || "")

    // there has to be stored some string
    setPayloadRecoil(payload)
    setPayload(payload)
  }
  return {
    nextStep,
    payload,
    template,
  }
}

export default useNextStep
