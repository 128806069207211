import { AtomEffect } from "recoil"
import { info } from "../logger"

export const sessionStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key)
    if (savedValue != null) {
      info(`Recoil init ${key} = ${savedValue}`)
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue: any) => {
      info(`Recoil onSet ${key} = ${newValue}`)
      sessionStorage.setItem(key, JSON.stringify(newValue))
    })
  }
