import { useTranslation } from "react-i18next"
import { CircularProgress } from "@mui/material"
import { css } from "@emotion/css"
import { useEffect } from "react"
import useNextStep from "../../hooks/useNextStep"
import { NextStepResTemplateEnum } from "../../generated-sources/state-machine"
import { useNavigate } from "react-router-dom"
import Path from "../Path"
import { useRecoilState } from "recoil"
import {
  idCaseState,
  originState,
  properInitiatedState,
  spRedirectUriState,
  templateState,
  tokenState,
  trackingIdState,
} from "../../atoms/application"
import { StringParam, useQueryParam } from "use-query-params"

/**
 * When is template from backend loaded make redirect to proper page
 */
const useTemplate = () => {
  const [templateRecoil] = useRecoilState(templateState)
  const navigate = useNavigate()
  const [idCaseQuery = null] = useQueryParam("idCase", StringParam)
  const [tokenUriQuery = null] = useQueryParam("token", StringParam)
  const [xTrackingIdQuery = null] = useQueryParam("xTrackingId", StringParam)
  const [originQuery = null] = useQueryParam("origin", StringParam)
  const [idCase, setIdCase] = useRecoilState(idCaseState)
  const [token, setToken] = useRecoilState(tokenState)
  const [xTrackingId, setXTrackingId] = useRecoilState(trackingIdState)
  const [origin, setOrigin] = useRecoilState(originState)

  useEffect(() => {
    if (idCaseQuery) {
      setIdCase(idCaseQuery)
    }
  }, [idCaseQuery, setIdCase])

  useEffect(() => {
    if (xTrackingIdQuery) {
      setXTrackingId(xTrackingIdQuery)
    }
  }, [xTrackingIdQuery, setXTrackingId])

  useEffect(() => {
    if (tokenUriQuery) {
      setToken(decodeURIComponent(tokenUriQuery))
    }
  }, [tokenUriQuery, setToken])

  useEffect(() => {
    if (originQuery) {
      setOrigin(originQuery)
    }
  }, [originQuery, setOrigin])

  useEffect(() => {
    if (
      templateRecoil ===
      NextStepResTemplateEnum.AuthorizeInProgress /* && tokenUriQuery*/
    ) {
      navigate(Path.AUTHORIZATION_IN_PROGRESS)
    }
    if (templateRecoil === NextStepResTemplateEnum.BankIdCodeExchange) {
      navigate(Path.BANK_ID_SUCCESS_AUTHORIZATION)
    }
  }, [templateRecoil, tokenUriQuery])
}

const IndexPage = () => {
  // Every call from state machine has to go through this site
  // If it happens whe set flag that it is properly initialized
  const [, setPropperInitialized] = useRecoilState(properInitiatedState)
  const [t] = useTranslation()
  const { nextStep, payload, template } = useNextStep()
  const [tokenUriQuery = null] = useQueryParam("token", StringParam)
  const [idCase, setIdCase] = useRecoilState(idCaseState)
  const [token, setToken] = useRecoilState(tokenState)
  const [xTrackingId, setXTrackingId] = useRecoilState(trackingIdState)
  useTemplate()
  const [spRedirectUri, setSpRedirectUri] = useRecoilState(spRedirectUriState)
  const [spRedirectUriQuery = null] = useQueryParam(
    "spRedirectUri",
    StringParam
  )

  setPropperInitialized(true)

  // read next step form BE
  useEffect(() => {
    if (idCase && tokenUriQuery && xTrackingId && tokenUriQuery === token) {
      nextStep({ statusCode: "AUTHORIZE_IN_PROGESS" })
    }
  }, [idCase, tokenUriQuery, token, xTrackingId])

  useEffect(() => {
    if (spRedirectUriQuery) {
      setSpRedirectUri(decodeURIComponent(spRedirectUriQuery))
    }
  }, [spRedirectUriQuery, setSpRedirectUri])

  return (
    <div
      className={css({
        height: "100vh",
        lineHeight: "100vh",
        textAlign: "center",
      })}
    >
      <CircularProgress />
    </div>
  )
}

export default IndexPage
