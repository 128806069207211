enum Path {
  INDEX = "/index",
  AUTHORIZATION_IN_PROGRESS = "/authorization-in-progress",
  USER_AUTHENTICATED = "/user_authenticated",
  BANK_ID_SUCCESS_AUTHORIZATION = "/bank-id-success-authorization",
  SUCCESS_AUTHORIZATION = "/success-authorization",
  ERROR_AUTHORIZATION = "/error-authorization",
}

export default Path
