import {HashRouter, Route, Routes} from "react-router-dom"
import IndexPage from "./index/IndexPage"
import PageNotFound from "./page_not_found/PageNotFound"
import Path from "./Path"
import TransactionAuthorizeInProgress from "./transaction_authorization_in_progress/TransactionAuthorizeInProgress"
import UserAuthenticated from "./user_authenticated/UserAuthenticated"
import BankIdSuccessAuthorization from "./bank_id_success_authorization/BankIdSuccessAuthorization"
import SuccessAuthorization from "./success_authorization/SuccessAuthorization";
import ErrorAuthorization from "./error_authorization/ErrorAuthorization";

const Router = () => {
  return (
      <Routes>
        <Route path={Path.INDEX} element={<IndexPage />} />
        <Route
          path={Path.AUTHORIZATION_IN_PROGRESS}
          element={<TransactionAuthorizeInProgress />}
        />
        <Route path={Path.USER_AUTHENTICATED} element={<UserAuthenticated />} />
        <Route
          path={Path.BANK_ID_SUCCESS_AUTHORIZATION}
          element={<BankIdSuccessAuthorization />}
        />
        <Route
          path={Path.SUCCESS_AUTHORIZATION}
          element={<SuccessAuthorization />}
        />
        <Route
          path={Path.ERROR_AUTHORIZATION}
          element={<ErrorAuthorization />}
        />
        {<Route path="/" element={<BankIdSuccessAuthorization />} />}
        {/* Because of limitation at bankid.cz we have default page has to be Success Authorization */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
  )
}

export default Router
