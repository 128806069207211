import { useTranslation } from "react-i18next"
import { CircularProgress } from "@mui/material"
import { css } from "@emotion/css"
import { StringParam, useQueryParam } from "use-query-params"
import {
  idCaseState,
  spRedirectUriState, tokenState,
  trackingIdState,
} from "../../atoms/application"
import { useRecoilState } from "recoil"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Path from "../Path"

const UserAuthenticated = () => {
  const [idCaseQuery = null] = useQueryParam("idCase", StringParam)
  const [xTrackingIdQuery = null] = useQueryParam("xTrackingId", StringParam)
  const [spRedirectUriQuery = null] = useQueryParam(
    "spRedirectUri",
    StringParam
  )
  const [tokenUriQuery = null] = useQueryParam( "token", StringParam)
  const [t] = useTranslation()
  const [idCase, setIdCase] = useRecoilState(idCaseState)
  const [xTrackingId, setXTrackingId] = useRecoilState(trackingIdState)
  const [spRedirectUri, setSpRedirectUri] = useRecoilState(spRedirectUriState)
  const [token, setToken] = useRecoilState(tokenState)
  const navigate = useNavigate()

  useEffect(() => {
    if (idCaseQuery) {
      setIdCase(idCaseQuery)
    }
  }, [idCaseQuery, setIdCase])

  useEffect(() => {
    if (xTrackingIdQuery) {
      setXTrackingId(xTrackingIdQuery)
    }
  }, [xTrackingIdQuery, setXTrackingId])

  useEffect(() => {
    if (spRedirectUriQuery) {
      setSpRedirectUri(decodeURIComponent(spRedirectUriQuery))
    }
  }, [spRedirectUriQuery, setSpRedirectUri])

  useEffect(() => {
    if (tokenUriQuery) {
      setToken(decodeURIComponent(tokenUriQuery))
    }
  }, [tokenUriQuery, setToken])

  useEffect(() => {
    if (
      idCaseQuery &&
      idCaseQuery === idCase &&
      xTrackingIdQuery &&
      xTrackingIdQuery === xTrackingId &&
      spRedirectUriQuery &&
      spRedirectUriQuery === spRedirectUri &&
      tokenUriQuery &&
      tokenUriQuery === token
    ) {
      navigate(Path.INDEX)
    }
  }, [
    idCaseQuery,
    xTrackingIdQuery,
    idCase,
    xTrackingId,
    spRedirectUri,
    spRedirectUriQuery,
    tokenUriQuery,
    token,
  ])

  return (
    <div
      className={css({
        height: "100vh",
        lineHeight: "100vh",
        textAlign: "center",
      })}
    >
      <CircularProgress />
    </div>
  )
}

export default UserAuthenticated
