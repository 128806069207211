import { css } from "@emotion/css"
import { CircularProgress } from "@mui/material"
import { useEffect, useMemo } from "react"
import { StringParam, useQueryParam } from "use-query-params"
import useNextStep from "../../hooks/useNextStep"
import { useRecoilState } from "recoil"
import { spRedirectUriState } from "../../atoms/application"
import Path from "../Path"
import { useNavigate } from "react-router-dom"

const BankIdSuccessAuthorization = () => {
  const [error = null] = useQueryParam("error", StringParam)
  const { nextStep, payload, template } = useNextStep()
  const [spRedirectUri, setSpRedirectUri] = useRecoilState(spRedirectUriState)
  const navigate = useNavigate()
  const query = useMemo(
    () =>
      new URLSearchParams(
        window.location.href.substring(window.location.href.indexOf("?"))
      ),
    [window.location.href]
  )
  const code = query?.get("code") || null

  // when is payload loaded make redirect to Bank ID page
  useEffect(() => {
    let url: URL
    let objPayload: any = {}
    if (payload) {
      // make from payload object
      try {
        objPayload = JSON.parse(payload)
      } catch (_) {
        throw "Payload after success bankid authorization cannot be parsed"
      }
      // there should be stored redirect uri to FE
      if (objPayload.spRedirectUri) {
        try {
          url = new URL(objPayload.spRedirectUri)
          window.location.href = url.href
          sessionStorage.clear()
        } catch (_) {
          throw "url to redirect to Bank Id is not valid"
        }
      } else {
        const uri = process.env.REACT_APP_SP_REDIRECT_URL
        if (!uri) {
          throw "REACT_APP_SP_REDIRECT_URL is not defined"
        }

        navigate(`${Path.SUCCESS_AUTHORIZATION}`)
      }
    }
  }, [payload])

  useEffect(() => {
    if (code) {
      // call next step
      // to body setup
      const requestBody = {
        code,
      }
      nextStep(requestBody)
    }
  }, [code])

  useEffect(() => {
    if (error) {
      if (spRedirectUri) {
        const uri = spRedirectUri
        if (uri.includes("?")) {
          window.location.replace(`${uri}&errorCode=${error}`)
        } else {
          window.location.replace(`${uri}?errorCode=${error}`)
        }
        sessionStorage.clear()
      } else {
        const uri = process.env.REACT_APP_SP_REDIRECT_URL
        if (!uri) {
          throw "REACT_APP_SP_REDIRECT_URL is not defined"
        }
        navigate(`${Path.ERROR_AUTHORIZATION}?errorCode=UNKNOWN`)
      }
    }
  }, [error, spRedirectUri])

  return (
    <div
      className={css({
        height: "100vh",
        lineHeight: "100vh",
        textAlign: "center",
      })}
    >
      <CircularProgress />
    </div>
  )
}

export default BankIdSuccessAuthorization
