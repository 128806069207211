import React, { ErrorInfo, useEffect } from "react"
import { Navigate } from "react-router-dom"
import { error } from "./logger"
import { useRecoilState } from "recoil"
import { spRedirectUriState } from "./atoms/application"

interface IErrorBoundaryState {
  hasError: boolean
}

interface IErrorBoundaryProps {}

const Redirect = () => {
  const [spRedirectUri] = useRecoilState(spRedirectUriState)

  useEffect(() => {
    const uri = spRedirectUri || process.env.REACT_APP_SP_REDIRECT_URL
    if (!uri) {
      throw "REACT_APP_SP_REDIRECT_URL is not defined"
    }
    window.location.replace(`${uri}?errorCode=UNKNOWN`)
  }, [spRedirectUri])
  return <div />
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(errorMessage: any, errorInfo: ErrorInfo) {
    this.setState({hasError: true})
    error(errorMessage)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Redirect />
    }

    // @ts-ignore
    return this.props.children
  }
}

export default ErrorBoundary
