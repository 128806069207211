/* tslint:disable */
/* eslint-disable */
/**
 * State Machine API
 * State Machine API to call via express gateway
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration"
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common"
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base"

/**
 *
 * @export
 * @interface NextStepReq
 */
export interface NextStepReq {
  /**
   *
   * @type {number}
   * @memberof NextStepReq
   */
  idCase?: number
}
/**
 *
 * @export
 * @interface NextStepRes
 */
export interface NextStepRes {
  /**
   *
   * @type {string}
   * @memberof NextStepRes
   */
  template?: NextStepResTemplateEnum
  /**
   * Specific payload for every template
   * @type {object | string}
   * @memberof NextStepRes
   */
  payload?: object | string
}

export const NextStepResTemplateEnum = {
  AuthorizeInProgress: "TRANSITION_AUTHORIZE_IN_PROGRESS",
  BankIdCodeExchange: "TRANSITION_BANK_ID_CODE_EXCHANGE",
} as const

export type NextStepResTemplateEnum =
  (typeof NextStepResTemplateEnum)[keyof typeof NextStepResTemplateEnum]

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary do next step
     * @param {NextStepReq} [nextStepReq] Is case id for next step for frontend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNextStatusPost: async (
      nextStepReq?: NextStepReq,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/next-status`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        nextStepReq,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary do next step
     * @param {NextStepReq} [nextStepReq] Is case id for next step for frontend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiNextStatusPost(
      nextStepReq?: NextStepReq,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NextStepRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiNextStatusPost(nextStepReq, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration)
  return {
    /**
     *
     * @summary do next step
     * @param {NextStepReq} [nextStepReq] Is case id for next step for frontend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiNextStatusPost(
      nextStepReq?: NextStepReq,
      options?: any
    ): AxiosPromise<NextStepRes> {
      return localVarFp
        .apiNextStatusPost(nextStepReq, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary do next step
   * @param {NextStepReq} [nextStepReq] Is case id for next step for frontend
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public apiNextStatusPost(
    nextStepReq?: NextStepReq,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .apiNextStatusPost(nextStepReq, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
