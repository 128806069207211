import { css } from "@emotion/css"
import { ReactComponent as DeloitteLogo } from "../../assets/deloitte_logo.svg"


const StatusLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        lineHeight: 1.4,
        textAlign: "center",
      })}
    >
      <div
        className={css({
          maxWidth: 320
        })}
      >
        <div
          className={css({
            marginBottom: 32
          })}
        >
          <DeloitteLogo width="280" />
        </div>

        { children }
      </div>
    </div>
  )
}

export default StatusLayout
