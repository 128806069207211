import { useTranslation } from "react-i18next"
import { css } from "@emotion/css"
import StatusLayout from "../../layouts/status_layout/StatusLayout";


const ErrorAuthorization = () => {
  const [t] = useTranslation()

  return (
    <StatusLayout>
      <p
        className={css({
          fontFamily: '"Helvetica", sans-serif',
          fontSize: 24,
        })}
      >
        {t("Došlo k chybě, zkuste to prosím znovu později.")}
      </p>
    </StatusLayout>
  )
}

export default ErrorAuthorization
