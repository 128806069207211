import { useTranslation } from "react-i18next"
import { css } from "@emotion/css"
import StatusLayout from "../../layouts/status_layout/StatusLayout";


const SuccessAuthorization = () => {
  const [t] = useTranslation()

  return (
    <StatusLayout>
      <p
        className={css({
          fontFamily: '"Helvetica", sans-serif',
          fontSize: 24,
        })}
      >
        {t("Vaše identita byla ověřená.")}<br />
        {t("Toto okno nyní můžete zavřít.")}
      </p>
    </StatusLayout>
  )
}

export default SuccessAuthorization
