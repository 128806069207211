import { useRecoilState } from "recoil"
import {idCaseState, tokenState, trackingIdState} from "../atoms/application"
import { useEffect, useState } from "react"
import axios from "axios"

export const useAxios = () => {
  // id case from backend
  const [idCase] = useRecoilState(idCaseState)
  const [xTrackingId] = useRecoilState(trackingIdState)
  const [token] = useRecoilState((tokenState))
  // Id of interceptor
  const [idCaseInterceptor, setIdCaseInterceptor] = useState<number | null>(
    null
  )
  // set interceptor
  useEffect(() => {
    const idAxios = axios.interceptors.request.use((request) => {
      if (idCase && request.headers) {
        request.headers["X-CaseId"] = idCase
      }
      if (xTrackingId && request.headers) {
        request.headers["X-TrackingId"] = xTrackingId
      }
      if (token && request.headers) {
        request.headers["Authorization"] = token
      }
      return request
    })
    if (idCaseInterceptor) {
      axios.interceptors.request.eject(idCaseInterceptor)
    }
    setIdCaseInterceptor(idAxios)
  }, [idCase, token, xTrackingId])
}
