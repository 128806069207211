import { atom } from "recoil"
import { sessionStorageEffect } from "./index"

export enum Keys {
  ID_CASE = "ID_CASE",
  PAYLOAD = "PAYLOAD",
  TEMPLATE = "TEMPLATE",
  TRACKING_ID = "TRACKING_ID",
  SP_REDIRECT_URI = "SP_REDIRECT_URI",
  TOKEN = "TOKEN",
  ORIGIN = "ORIGIN",
  PROPER_INITIATED = "PROPER_INITIATED",
}

export const idCaseState = atom({
  key: Keys.ID_CASE,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.ID_CASE)],
})

export const payloadState = atom({
  key: Keys.PAYLOAD,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.PAYLOAD)],
})

export const templateState = atom({
  key: Keys.TEMPLATE,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.TEMPLATE)],
})

export const trackingIdState = atom({
  key: Keys.TRACKING_ID,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.TRACKING_ID)],
})

export const spRedirectUriState = atom({
  key: Keys.SP_REDIRECT_URI,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.SP_REDIRECT_URI)],
})

export const tokenState = atom({
  key: Keys.TOKEN,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.TOKEN)],
})

export const originState = atom({
  key: Keys.ORIGIN,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.ORIGIN)],
})

export const properInitiatedState = atom({
  key: Keys.PROPER_INITIATED,
  default: false,
})
