// @ts-nocheck
import React, { useEffect } from "react"
import Router from "./pages/Router"
import { useAxios } from "./hooks/useAxios"
import { useLocation, useNavigate } from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import ErrorBoundary from "./ErrorBoundary"
import { useRecoilState } from "recoil"
import { spRedirectUriState } from "./atoms/application"

// @ts-ignore
const RouteAdapter = ({ children }) => {
  const navigate: any = useNavigate()
  const location: any = useLocation()

  const adaptedHistory = React.useMemo(
    () => ({
      // @ts-ignore
      replace(location) {
        navigate(location, { replace: true, state: location.state })
      },
      // @ts-ignore
      push(location) {
        navigate(location, { replace: false, state: location.state })
      },
    }),
    [navigate]
  )
  return children({ history: adaptedHistory, location })
}

function App() {
  useAxios()
  const [spRedirectUri, setSpRedirectURI] = useRecoilState(spRedirectUriState)

  useEffect(() => {
    const response = fetch("/api/env")
    response.then(async (response) => {
      const json = await response.json()
      // setSpRedirectURI(json.REACT_APP_SP_REDIRECT_URL.toString())
    })
  }, [])

  // if (spRedirectUri == null) {
  //   return null
  // }

  return (
    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </QueryParamProvider>
  )
}

export default App
