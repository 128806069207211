import { CircularProgress } from "@mui/material"
import { css } from "@emotion/css"
import { useEffect } from "react"
import { useRecoilState } from "recoil"
import {
  originState,
  payloadState,
  properInitiatedState,
  spRedirectUriState,
} from "../../atoms/application"
import axios from "axios"

const TransactionAuthorizationInProgress = () => {
  const [payloadRecoil, setPayloadRecoil] = useRecoilState(payloadState)
  const [origin] = useRecoilState(originState)
  const [spRedirectUri] = useRecoilState(spRedirectUriState)
  // Get information that page isn't properly initiated
  const [properInitiated] = useRecoilState(properInitiatedState)

  // when is payload loaded make redirect to Bank ID page
  useEffect(() => {
    let url: URL
    if (payloadRecoil) {
      try {
        url = new URL(payloadRecoil)
      } catch (_) {
        throw "url to redirect to Bank Id is not valid"
      }
      if (url.href) {
        // window.location.href = url.href
        if (origin === "test") {
          axios.get("/bankid-redirect", {
            params: {
              url: url.href,
            },
          })
        } else {
          window.location.href = url.href
        }
        setPayloadRecoil("")
      }
    } else {
      if (!properInitiated && spRedirectUri) {
        window.location.replace(spRedirectUri)
      }
    }
  }, [properInitiated, payloadRecoil])

  return (
    <div
      className={css({
        height: "100vh",
        lineHeight: "100vh",
        textAlign: "center",
      })}
    >
      <CircularProgress />
    </div>
  )
}

export default TransactionAuthorizationInProgress
